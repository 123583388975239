import { Heading, Picture, Tag, TextLink } from '@troon/ui';
import { Icon } from '@troon/icons';
import { For, Suspense, Show } from 'solid-js';
import { twJoin } from '@troon/tailwind-preset/merge';
import { createAsync } from '@solidjs/router';
import dayjs from '@troon/dayjs';
import { gql } from '../../../graphql';
import { cachedQuery } from '../../../graphql/cached-get';
import { createNumberFormatter } from '../../../modules/number-formatting';
import { dayTimeToDate, formatDateTimeAttribute } from '../../../modules/date-formatting';
import type { RouteDefinition } from '@solidjs/router';
import type { ParentProps } from 'solid-js';

export default function AccessBenefits() {
	const data = createAsync(() => getData({}), { deferStream: false });
	const numberFormatter = createNumberFormatter();

	return (
		<div class="flex flex-col gap-4 text-lg lg:gap-6">
			<GridBox class="flex basis-full flex-col gap-12 py-6 lg:py-16">
				<div class="px-6 md:px-16">
					<Heading as="h3" size="h4">
						Featured Troon Access Deals
					</Heading>
				</div>
				<div role="presentation" class="flex gap-4 overflow-x-auto px-6 md:px-16">
					<Suspense>
						<For each={data()?.deals.flatMap((deal) => deal.deals)}>
							{(savings) => (
								<div class="flex min-w-72 max-w-96 grow flex-col gap-4 rounded border border-neutral bg-white p-3 md:p-6">
									<div class="flex items-start justify-between">
										<div class="size-16 overflow-hidden rounded-md border border-neutral p-1">
											<Picture
												src={savings.facility.metadata?.logo?.url}
												alt=""
												sizes={[[100, 100]]}
												class="size-full"
												mode="contain"
												crop="center"
											/>
										</div>
										<Tag appearance="access">{savings.discount.displayValue} off</Tag>
									</div>

									<div class="flex flex-col">
										<Show when={dayjs(dayTimeToDate(savings.dayTime))}>
											{(day) => (
												<time
													class="block text-3xl font-semibold"
													datetime={formatDateTimeAttribute(dayTimeToDate(savings.dayTime))}
												>
													{day().format('h:mm')} {day().format('A')}
												</time>
											)}
										</Show>
										<p class="text-neutral-700">{savings.facility.name}</p>
										<p class="text-sm text-neutral-700">
											<Icon name="users" />{' '}
											{savings.minPlayers !== savings.maxPlayers
												? numberFormatter().formatRange(savings.minPlayers, savings.maxPlayers)
												: numberFormatter().format(savings.minPlayers)}
										</p>
									</div>

									<p class="text-xl">
										<span class="text-neutral-800 line-through">{savings.publicRate.displayValue}</span>{' '}
										<span class="font-semibold text-brand-600">{savings.accessPlusRate.displayValue}</span>
									</p>
								</div>
							)}
						</For>
					</Suspense>
				</div>
				<p class="max-w-screen-md px-6 md:px-16">
					<b>Savings on every round.</b> Save a minimum of 15% off the public rate on every round. Troon Access+
					cardholders enjoy up to 50% off tee times within 78 hours.
				</p>
			</GridBox>

			<div class="flex flex-col gap-4 lg:flex-row lg:gap-6">
				<GridBox class="h-fit shrink-0 justify-center lg:basis-1/2">
					<div class="flex flex-col gap-4 py-6 lg:py-16">
						<div class="flex flex-col gap-4 px-6 lg:px-16 ">
							<p>
								<b>Access to over 150 world-class courses.</b> From Kapalua, to Gamble Sands and Troon North – your
								Troon Access benefits give you a network of premier courses to play.
							</p>
							<p class="text-base">
								<TextLink href="#explore-courses">
									Explore Courses <Icon name="arrow-right-md" />
								</TextLink>
							</p>
						</div>

						<Picture
							src="https://images.ctfassets.net/rdsy7xf5c8dt/1TlSRTAPnGbThoqGNkCTvH/e6e471a72e5a5852c754e7e26f02b3c1/course-graphic.png"
							alt=""
							sizes={[[628, 442]]}
							class="w-full"
						/>
					</div>
				</GridBox>

				<div class="flex flex-col gap-4 lg:gap-6">
					<GridBox class="p-6 lg:p-16">
						<div class="flex items-start gap-8">
							<div class="relative shrink-0">
								<span class="absolute inset-0 z-0 rounded-full bg-gradient-to-t from-brand-700 to-brand-200 motion-safe:animate-ping" />
								<div class="relative z-10 rounded-full bg-gradient-to-t from-neutral-950 to-brand-700 p-3 text-white ">
									<Icon name="tag" class="size-8" />
								</div>
							</div>
							<div class="flex flex-col gap-4">
								<p>
									<b>Exclusive perks & partner offers.</b> Enjoy additional benefits like partner offers and 10% savings
									on pro-shop merchandise.
								</p>
								<p class="text-base">
									<TextLink href="/access#benefits">
										View Offers <Icon name="arrow-right-md" />
									</TextLink>
								</p>
							</div>
						</div>
					</GridBox>

					<GridBox class="p-6 lg:row-span-2 lg:p-16">
						<Picture
							src="https://images.ctfassets.net/rdsy7xf5c8dt/2sFoL0JCi7NRHiaXf1U8Ph/81ff38cec96bf699e346bcd691e67017/guest-pass-share-transparent.png"
							sizes={[
								[500, 274],
								[1000, 547],
							]}
							alt=""
							class="mx-auto"
						/>
						<div class="flex flex-col gap-4">
							<p>
								<b>Guest Passes</b> allow your playing partners to play at the same discounted rate as you for a given
								round. All Troon Access members receive <b>3 Guest Passes</b> per/year with no restrictions on when they
								can be used.
							</p>
							<p class="text-base">
								<TextLink href="/access/guest-passes">
									Learn More<span class="sr-only"> about guest passes</span> <Icon name="arrow-right-md" />
								</TextLink>
							</p>
						</div>
					</GridBox>
				</div>
			</div>
		</div>
	);
}

function GridBox(props: ParentProps<{ class?: string }>) {
	return (
		<div class={twJoin('flex flex-col justify-center gap-6 rounded border border-neutral bg-neutral-100', props.class)}>
			{props.children}
		</div>
	);
}

const accessQuery = gql(`query accessBenefits {
	deals: troonAccessTeeTimeDealsByCourse(courseLimit: 6, teeTimeLimit: 1) {
		deals { ...Savings }
	}
}
fragment Savings on TroonAccessTeeTimeDeal {
	publicRate { ...Currency }
	accessPlusRate { ...Currency }
	discount { ...Currency }
	dayTime { ...DayTime }
	minPlayers
	maxPlayers
	facility {
		name
		metadata { logo { url } }
	}
}`);

const getData = cachedQuery(accessQuery);

export const route = { info: { hasHero: false } } satisfies RouteDefinition;
